import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import color from "./colors"
import { media } from "./layout"
import { Wrapper } from "./layout"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const Header = ({ siteTitle }) => (
  <>
    <div id="top"></div>
    <header>
      <Wrapper>
        <MenuWrapper>
          <Logotype href="#top"><Logo src="/prime73-logo.svg" />Prime73</Logotype>
          <OnePageMenuItem collapse="md" href="#meet-soe">Introducing SOE</OnePageMenuItem>
          <OnePageMenuItem collapse="md" href="#features">Features</OnePageMenuItem>
          <OnePageMenuItem collapse="md" href="#process">Process</OnePageMenuItem>
          <OnePageMenuItem collapse="md" href="#pricing">Pricing</OnePageMenuItem>
          <OnePageMenuItem collapse="md" href="#faq">FAQ</OnePageMenuItem>
          <OnePageMenuItem collapse="md" href="#about-us">About us</OnePageMenuItem>
          <OnePageMenuItem collapse="md" href="#contact">Contact</OnePageMenuItem>
        </MenuWrapper>
      </Wrapper>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const OnePageMenuItem = styled.a`
  margin: 0 1rem;
  padding: 0.25rem 0.5rem;
  color: ${color.primaryDark};
  font-weight: 800;
  cursor: pointer;
  ${() => media.lg(`
    padding: 0.25rem 0.375rem;
    margin: 0 0.25rem;
  `)}
  &:hover {
    text-decoration: none;
    color: ${color.primary};
  }
  ${props => props.collapse && media[props.collapse](`
    display: none;
  `)}
`
const Logotype = styled(OnePageMenuItem)`
  white-space: nowrap;
  font-size: 1.25rem;
  margin-right: 2rem;
  padding: 0;
  margin: 0 1rem 0 0;
`
/*
const Menu = styled(Wrapper)`
  padding: 1.5rem;
  border-bottom: 1px solid #e6e6e6;
`
*/
const Logo = styled.img`
  width: 50px;
  margin: 0;
  vertical-align: middle;
`
const MenuWrapper = styled.div`
  padding: 1.5rem 0;
`
