import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

export const media = {
  xs: (styles) => `
    @media only screen and (max-width: 480px) {
      ${styles}
    }
  `,
  sm: (styles) => `
    @media only screen and (max-width: 768px) {
      ${styles}
    }
  `,
  md: (styles) => `
    @media only screen and (max-width: 980px) {
      ${styles}
    }
  `,
  lg: (styles) => `
    @media only screen and (max-width: 1280px) {
      ${styles}
    }
  `,
  xl: (styles) => `
    @media only screen and (min-width: 1280px) {
      ${styles}
    }
  `,
}

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.5rem;
  ${() => media.sm(`
    padding: 0 1.25rem;
  `)}
`
export const Spacer = styled.div`
  height: ${props => props.h}rem;
`
export const Row = styled.div`
  display: flex;
  ${props => props.fullWidth && media[props.fullWidth](`
    flex-flow: column;
  `)}
  ${props => props.wrap && media[props.wrap](`
    flex-flow: wrap;
  `)}
`
export const Col = styled.div`
  flex: ${props => props.size || '1'};
  ${props => props.halfWidth && media[props.halfWidth](`
    flex-basis: 50%;
  `)}
  ${() => media.xs(`
    flex-basis: auto;
  `)}
  ${props => props.collapse && media[props.collapse](`
    display: none;
  `)}
`
export const Br = styled.br`
  ${props => props.collapse && media[props.collapse](`
    display: none;
  `)}
`

