import React from "react"
import styled from "styled-components"
import scrollTo from 'gatsby-plugin-smoothscroll'

import color from "./colors"
import { media, Wrapper, Spacer, Row, Col } from "./layout"
import IconInstagram from "../images/icon-instagram.svg"
import IconTwitter from "../images/icon-twitter.svg"
import IconFacebook from "../images/icon-facebook.svg"
import IconYoutube from "../images/icon-youtube.svg"
import { LcarsWrapper, Elbow, Line, RoundEnd } from "../components/LcarsEls"
import bpmLogo from "../images/b-logo.svg"

const Footer = () => (
  <footer>
    <Spacer h="2" />

    <LcarsWrapper edge flipV height={2}>
      <Elbow size={1} height={0.5} color={color.light} />
      <Line collapse="lg" size={2} color={color.primary} />
      <Line size={3} />
      <Line size={3} color={color.primaryLighter} />
      <RoundEnd size={1} />
    </LcarsWrapper>

    <Spacer h="4" />

    <Wrapper>
      <LinksRow>
          {/*
          <p><OnePageLink onClick={() => scrollTo("#meet-soe")}>Platform</OnePageLink></p>
          <p><OnePageLink onClick={() => scrollTo("#pricing")}>Pricing</OnePageLink></p>
          <p><OnePageLink onClick={() => scrollTo("#about-us")}>About us</OnePageLink></p>
          <p><OnePageLink onClick={() => scrollTo("#contact")}>Contact</OnePageLink></p>
          */}
  {/*
        <Col>
          <p><OnePageLink onClick={() => scrollTo("#meet-soe")}>Platform</OnePageLink></p>
        </Col>
        <Col>
          <p><OnePageLink onClick={() => scrollTo("#pricing")}>Pricing</OnePageLink></p>
        </Col>
        <Col>
          <p><OnePageLink onClick={() => scrollTo("#about-us")}>About us</OnePageLink></p>
          {/*
          <p><SecondaryLink to="/about-us">Testimonials</SecondaryLink><br />
          <SecondaryLink to="/about-us">History</SecondaryLink></p>
        */}
  {/*
        </Col>
        <Col>
          <p><OnePageLink onClick={() => scrollTo("#contact")}>Contact</OnePageLink></p>
  */}
          {/*
          <p><SecondaryLink to="/feedback">Contact us</SecondaryLink><br />
          <SecondaryLink to="/feedback">Vote for next</SecondaryLink><br />
          <SecondaryLink to="/feedback">Work with us</SecondaryLink></p>
        */}
  {/*
        </Col>
  */}
      </LinksRow>
    </Wrapper>

    <Spacer h="2" />

    {/*
    <LcarsWrapper edge height={2}>
      <RoundEnd flip size={1} />
      <Line size={6} />
      <Line size={2} color={color.primary} />
      <RoundEnd size={1} />
    </LcarsWrapper>
    */}

    <Wrapper>
      {/*
      <SocialIcons><span>Follow us: </span><SocialIcon src={IconInstagram} /> <SocialIcon src={IconFacebook} /> <SocialIcon src={IconYoutube} /> <SocialIcon src={IconTwitter} /></SocialIcons>
      */}
      <Copyright> © BPMspace GmbH {new Date().getFullYear()}. With love from Munich. <a title="Imprint" href="https://www.bpmspace.com/#contact" target="_blank" rel="noopener">Imprint</a> | <a title="Privacy policy" href="https://www.bpmspace.com/#contact" target="_blank" rel="noopener">Privacy policy</a></Copyright>
      <p style={{textAlign: 'center'}}><a href="https://www.bpmspace.com/"><BpmLogo style={{width: '50px'}} src={bpmLogo} /></a></p>
    </Wrapper>
  </footer>
)

/*
const SecondaryLink = styled(props => <Link {...props} />)`
  font-weight: normal;
  font-size: 0.875rem;
  &:hover {
    text-decoration: none;
    color: ${color.primary};
  }
`
*/
const BpmLogo = styled.img`
  display: inline-block;
  text-align: center;
  margin-bottom: 1rem;
`
const LinksRow = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: center;
  ${() => media.md(`
    justify-content: center;
  `)}
`
const Copyright = styled.small`
  margin: 1rem 0 2rem 0;
  display: block;
  text-align: center;
`
const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  line-height: 35px;
  ${() => media.md(`
    justify-content: center;
  `)}
`
const SocialIcon = styled.img`
  width: 35px;
  margin-left: 1rem;
`
const OnePageLink = styled.a`
  cursor: pointer;
  margin: 0 1rem;
  white-space: nowrap;
  ${() => media.xs(`
    margin: 0 0.5rem;
  `)}
`

export default Footer

