const color = {
  primary: '#273c7f',
  primaryDark: '#000537',
  primaryLight: '#517ec4',
  primaryLighter: '#779ad1',
  primaryLightest: '#9db6de',
  secondary: '#e79933',
  secondaryLight: '#ecb061',
  white: '#fefefe',
  black: '#262626',
  light: '#e6e6e6',
  gray: '#787878',
  grayLight: '#a3a3a3',
}

export default color;

