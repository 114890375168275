import React from "react"
import styled from "styled-components"

import color from "./colors"
import { media } from "./layout"

// Elbow

export const Elbow = ({...props}) => (
  <ElWrapper {...props}>
    <InnerElbow color={props.color} />
    <OuterElbow color={props.color} />
  </ElWrapper>
)

const InnerElbow = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  left: 80px;
  border-top: 20px solid ${props => props.color || color.primaryLight};
  border-left: 20px solid ${props => props.color || color.primaryLight};
  border-top-left-radius: 40px;
  ${() => media.md(`
    left: 0px;
    border-top-width: 12px;
    border-left-width: 20px;
    border-top-left-radius: 30px;
  `)}
`
const OuterElbow = styled(InnerElbow)`
  width: 130px;
  left: 0;
  border-left-width: 100px;
  ${() => media.md(`
    width: 30px;
    border-left-width: 15px;
  `)}
`
const ElWrapper = styled.div`
  flex: ${(props) => props.size || '1'};
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: ${props => props.noPadding ? '0': '5px'};
  ${ props => props.collapse && media[props.collapse](`
    display: none;
  `)}
`

// Line

export const Line = ({...props}) => (
  <ElWrapper {...props}>
    <LineDiv color={props.color} />
  </ElWrapper>
)

const LineDiv = styled.div`
  top: 0;
  height: 20px;
  background-color: ${props => props.color || color.primaryLight};
  ${() => media.md(`
    height: 12px;
  `)}
`

// RoundEnd

export const RoundEnd = ({...props}) => (
  <ElWrapper {...props}>
    <StyledRoundEnd color={props.color} flip={props.flip} />
  </ElWrapper>
)

const StyledRoundEnd = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${props => props.color || color.primaryLight};
  transform: scaleX(${props => props.flip ? '-1' : '1'});
  right: ${props => props.flip ? '0px' : 'auto'};
  ${() => media.md(`
    height: 12px;
    width: 12px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  `)}
`

// Circle

export const Circle = ({...props}) => (
  <ElWrapper {...props}>
    <StyledCircle {...props} />
  </ElWrapper>
)

const StyledCircle = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 10px;
  background-color: ${props => props.color || color.primaryLight};
  right: ${props => props.right ? '0px' : '50%'};
  left: ${props => props.left ? '0px' : '50%'};
  transform: translateX(${props => props.center ? '-10px' : '0'});
`

// Wrapper

export const LcarsWrapper = ({children, ...props}) => (
  <StyledLcarsWrapper {...props} >
    {children}
  </StyledLcarsWrapper>
)

const StyledLcarsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1700px;
  margin: 0.5rem auto 0 auto;
  height: ${props => props.height ? props.height * 50 + 'px' : '50px'};
  transform: scale(${props => props.flipH && props.flipV ? '-1,-1' : props.flipH ? '-1,1' : props.flipV ? '1,-1' : '1,1'});
  padding: 0 ${props => props.edge ? '0.875rem 0 0.875rem' : '0 0 0'};
`

